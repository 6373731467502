import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { ModuleManagementService } from 'src/app/core/services/module-management.service';
import { PulzoHubService } from 'src/app/core/services/pulzo-hub.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { DataItem, filterByModule, RemoveModule, ResponseModel, section } from 'src/app/models/moduleManagement';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-modules-editor',
  templateUrl: './home-modules-editor.component.html',
  styleUrls: ['./home-modules-editor.component.css'],
})
export class HomeModulesEditorComponent {
  moduleList: DataItem [] = [];
  sectionPast: section [] = [];
  templateNames: filterByModule[] = [];
  menus: { [key: string]: boolean } = {}; 

  inputState:boolean = false;
  showMenu = false;
  showMenuStatus = false;
  selectedOption!: string;
  selectedStatus!: string;
  turn = false;
  jsonForm!: FormGroup;
  selectedTemplate: string = ''; 
  selectedId: number;
  modalOpen: boolean = false;


  constructor(private fB: FormBuilder,private moduleService: ModuleManagementService,private sanitizer:DomSanitizer, private modalService: ModalService ,private logoutSrv: AuthService, private router: Router, private storageService: StorageService, private pulzoHubService: PulzoHubService){
    
    this.jsonForm = this.fB.group({
      name: [''],
      typeModule:[''],
      status:['']
    });

  }

  
  ngOnInit(templateName: string): void {
    this.filterByTemplate(templateName);
    this.toggleModuleStatus('enable');
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  menuAcviteNot(moduleId: string){
    this.menus[moduleId] = !this.menus[moduleId];
  }

  selectOption(moduleName: string) {
    this.selectedOption = moduleName;
    this.showMenu = false;
  }

  selectStatus(status: string) {
    this.selectedStatus = status;
    this.showMenuStatus = false;
  }

  toggleActive(value: boolean) {
    this.turn = value;
  }

  offClickHandler(event: MouseEvent) {
    const menuFilterElement = document.getElementById('menu-filter');
    if (menuFilterElement && !menuFilterElement.contains(event.target as Node)) {
      this.turn = false;
    }
  }


  editorNotSpecialCharacters(editor: string) {
    let modifiedEditor = editor; 
  
    const index = Math.min(modifiedEditor.indexOf('.'), modifiedEditor.indexOf('@'));
    if (index !== -1) {
      modifiedEditor = modifiedEditor.substring(0, index);
      modifiedEditor = modifiedEditor.charAt(0).toUpperCase() + modifiedEditor.slice(1) ;
    }
    
    return modifiedEditor; 
  }
  
  callStateModule(id:number): void
  {
    this.moduleService.StateModule(id, this.inputState).subscribe((response) => {
        console.log('Respuesta del servicio:', response);
    });
  }

  updateStateModule(id:number, inputStatus:boolean): void
  {
    this.moduleService.StateModule(id, inputStatus).subscribe((response) => {
    });
  }


  toggleModuleStatus(status: string) {
      const statusAllow = ['enable','disabled'];

    if (statusAllow.includes(status)) {
        this.jsonForm.get('status').setValue(status);
        this.jsonForm.get('name').setValue('');

          this.searchInfo('status', status);
      } 
  }


  searchModule(){
    const name = this.jsonForm.get('name')?.value;
    if (name && name !== '') {
        this.searchInfo('name', name);
    }
  }

  searchForTemplate(){
    const typeModule = this.selectedId; 
    if (typeModule && typeModule !== -1) {
        this.searchInfo('template', typeModule.toString());
    }
  }

  searchForState(){
    const status = this.jsonForm.get('status')?.value;
    if (status && status !== '') {
      this.searchInfo('status', status);
    }
  }


  onTemplateSelect(id: any) {
    this.selectedTemplate = this.templateNames.find(template => template.id === id)?.name || '';
    this.selectedId = id;
    this.showMenu = false;
    
    this.searchForTemplate();
  }

  filterByTemplate(templateName: string) {
    this.moduleService.filterListTemplatesModules().subscribe(
        (response: ResponseModel) => {
            const templateList = response.data;
            this.templateNames = templateList.map(template => template);
        },
        (error: any) => {
            console.error('Error occurred while fetching data:', error);
        }
    );
  }


  updateStatusModule(id: number, status: string) {
    this.menus[id] = false;
    this.moduleService.updateModuleStatus(id, status).subscribe(
      (response) => {
        this.searchForState();
      },
      (error) => {
        console.error('Error al actualizar el estado del módulo:', error);
      }
    );
  }

  //Para sanitizar contenido Html y que acepte los estilos en este caso la url de icon
  getIconUrl(icon: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(icon);
  }

  searchInfo(field: string, value: string) {
      this.moduleService.getEventListModule(field, value).subscribe((response: ResponseModel) => {
        this.moduleList = response.data;
          //@ts-ignore
          this.sectionPast = this.moduleList;
      });
  }

  editModule(id:number){
    this.router.navigate(['/edit-module'], {queryParams: {id : id}});
  }

  deleteModuleNew(){
    const formToSend = {
      title: "Eliminar Módulo",
      description: "¿Estás seguro de que deseas eliminar?",
      placeholder: "Elminar...",
      nameButton: "Eliminar Módulo",
      apiToSearch: "EliminarModule"
    }

    this.modalService.setData(formToSend);
    this.modalOpen = true;
    this.router.navigate(['/delete-modal']);

}


  deleteModule(idModule: number): void {
     let modul: RemoveModule = {id: idModule} as RemoveModule;

    Swal.fire({
      text: '¿Está seguro de eliminar este módulo?',
       icon: 'warning',
       showCancelButton: true,
       confirmButtonText: 'Aceptar',
       cancelButtonText: 'Cancelar',
     }).then((confirmDelete) => {
       if (confirmDelete.isConfirmed) {
        this.moduleService.deleteModules(modul).subscribe(() => {
           Swal.fire({
             text: 'Se ha eliminado el boletín de noticias correctamente',
             icon: 'success',
           });
          
         });
     }
    });

  }

}
