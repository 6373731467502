import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { VersionerUrlService } from 'src/app/core/services/versioner-url.service';
import { Generator } from 'src/app/models/verioner-url';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-versioner',
  templateUrl: './home-versioner.component.html',
  styleUrls: ['./home-versioner.component.css']
})
export class HomeVersionerComponent implements OnInit {
  formUrl: FormGroup;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private versionerService: VersionerUrlService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.formUrl = this.fb.group({
      url: ['', [Validators.required, Validators.pattern('(https?://.*)')]] // Validación para URLs
    });
  }

  
  sendUrl() {
    if (this.formUrl.invalid) {
      Swal.fire('', 'El campo es requerido o no es válido', 'warning');
    } else {

      Swal.fire({
        title: 'Espere por favor...',
        didOpen: () => {
            Swal.showLoading()
        },
        allowOutsideClick: false,
      });
  
      const articleUrl: Generator = { 
        url: this.formUrl.value.url 
      };
  
      this.versionerService.generarVersion(articleUrl).subscribe({
        next: (response) => {
         
          if (response.code === '200' && response.data){
            sessionStorage.setItem('versionData', JSON.stringify(response));
            this.router.navigate(['/article-versioner']);

            //console.log('Response:', response);
            
          } else {
            console.error('Error en la respuesta de la API:', response.message);
            Swal.fire('', 'Error en la respuesta de la API', 'error');
          }
         
        },
        error: (err) => {
          console.error('Error al generar la versión del artículo, intente nuevamente:', err);
          Swal.fire('', 'Error al generar la versión del artículo, intente nuevamente', 'error');
        },
        complete: () => {
  
          Swal.close();
        }
      });
    }
  }
  
}
