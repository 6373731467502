import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, Generator } from 'src/app/models/verioner-url'; 
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionerUrlService {
  private baseUrl = `${env.apiCerberoUrl}`;

  constructor(private http: HttpClient) { }

  generarVersion(url: Generator) {
    return this.http.post<ApiResponse>(`${this.baseUrl}/request?event=generador-texto`, url);
  }

}
