import { Component } from '@angular/core';
import { DuplicateTemplateService } from 'src/app/core/services/duplicate-template.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModuleManagementService } from 'src/app/core/services/module-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingJson } from 'src/app/models';
import Swal from 'sweetalert2';
import { CreateTemplateService } from 'src/app/core/services/create-template.service';
import { EditTemplateService } from 'src/app/core/services/edit-template.service';

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.css']
})
export class EditTemplateComponent {
  receivedData: any[] = [];
  jsonForm!: FormGroup;
  datos: any[] = [];
  keyOpening: string = '{';
  keyClosing: string = '},';
  parametroId: number = 0;
  settingJson: SettingJson ;
  id:string = "";

  selectedFileIcon: File[] = [];

  iconErrors = {
    size: false,
    format: false,
    ErrorMB: [],
    ErrorMBErrorMBErrorMB: [],
    ErrorFormat: [],
    ToShowMB: "",
    namesErrorToShowFormat: "",
    limitInMB: 3,
    minW: 1024,
    arrayPath: [],
    nameImage: "",
    descriptionImage: "",
    copyrightImage: "",
    authorImage: "",
    tags: "",
  }

  selectedMiniatureFiles: File[] = [];

  MiniatureErrors = {
    maxSize: 2,
    miniFiles: [],
    FilesErrorMB: [],
    FilesFormat: [],
    errorMsgForMB: false,
    errorMsgForFormat: false,
    miniatureMessageMB: "",
    miniatureMessageFormat: "",
  }

  // URL Archivos
  urlMiniature: string = "";
  urlIcon: string = "";
  TemplateNameInfo: string = '';
  pulzoRevolution: string = '';
  isCreatedIcon: boolean = false;
  isCreatedThumbnail: boolean = false;

  constructor(private DuplicateTemplateService: DuplicateTemplateService, private fB: FormBuilder, private srv: ModuleManagementService, private route: ActivatedRoute, private router: Router, private serviceTemplate: CreateTemplateService, private createTemplateService: CreateTemplateService, private editTemplateService: EditTemplateService) {
    this.settingJson = {} as SettingJson;
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });

    this.jsonForm = this.fB.group({
      nameTemplate: ['', Validators.required],
      properties: [[], Validators.required],
      type: [''],
      name: [''],
      quantity: [],
      thumbnail: ['', Validators.required],
      icon:['', Validators.required],
      view_pulzo_revolution: ['', Validators.required],
    });

    this.DuplicateTemplateService.getDataTemplateByID(parseInt(this.id, 10)).subscribe((response) =>{
       this.receivedData = response.data;
     });

   }

  ngOnInit(): void {
    this.fetchDataTemplate().then(() => {
      this.jsonForm = this.fB.group({
        id: this.receivedData['id'],
        //@ts-ignore
        nameTemplate: [this.receivedData['name'], Validators.required],
        //@ts-ignore
        properties: [this.receivedData['properties'], Validators.required],
        type: [''],
        name: [''],
        quantity: [],
        //@ts-ignore
        thumbnail: [this.receivedData['thumbnail'], Validators.required],
        //@ts-ignore
        icon:[this.receivedData['icon'], Validators.required],
        //@ts-ignore
        view_pulzo_revolution: [this.receivedData['view_pulzo_revolution'], Validators.required],
      });
  
      this.datos = this.jsonForm.get('properties')?.value;
    });
  }

  async fetchDataTemplate(): Promise<void> {
    try {
      const response = await this.DuplicateTemplateService.getDataTemplateByID(parseInt(this.id, 10)).toPromise();
      this.receivedData = response.data;
    } catch (error) {
      console.error('Error al cargar el template:', error);
    }
  }

  changeState(){
    if(this.datos.length>0){
      this.jsonForm.setValue({
        name: this.jsonForm.get('name')?.value,
        properties: this.datos,
        thumbnail:  this.urlMiniature,
        icon:  this.urlIcon,
        view_pulzo_revolution: this.jsonForm.get('view_pulzo_revolution')?.value
      });
    }
  }

  obtenerPulzoRevolution(): void {
    this.createTemplateService.obtenerPulzoRevolution().subscribe(
      (data) => {
        // Almacenar la información obtenida en la variable pulzoRevolution
        this.pulzoRevolution = data.view_pulzo_revolution;
      },
      (error) => {
        console.error('Error al obtener la información de pulzo revolution:', error);
      }
    );
  }

  submitPreview(){
      const quantityValue: string = this.jsonForm.get('quantity')?.value;
      const quantityInt: number = parseInt(quantityValue);
      const formData = {
        name: this.jsonForm.get('name')?.value,
        type: this.jsonForm.get('type')?.value,
        quantity: quantityInt,
      }
      this.datos.push(formData);
      this.jsonForm.get('name')?.setValue('');
      this.jsonForm.get('quantity')?.setValue('');
      this.jsonForm.get('properties')?.setValue({ path: this.datos });

      this.changeState();
  }

  elementDelete(index: number) {
    this.datos.splice(index, 1);
  }

  saveAll(){
    if(this.datos.length > 0){
      
      const jsonStruct = this.datos.map(data => ({
        name: data.name,
        type: data.type,
        quantity: data.quantity
      }));

      const setting: SettingJson = {
        id : this.parametroId,
        json_struct: jsonStruct,
      };

      try {
        this.srv.saveJson(setting).subscribe(() =>{
          
            Swal.fire({
              text: 'Se ha agregado correctamente el Json',
              icon: 'success',
            });
            this.datos = [];
            this.router.navigate(['/home-module-coordinador']);
        })
      } catch (error) {
        console.log(error);
      }
    }
  }

  cancel(){
    this.router.navigate(['/home-module-coordinador']);
  }

  onSelectIcon(event: any) {
    this.selectedFileIcon = event.addedFiles as File[];
    const maxSizeInMB = this.iconErrors.limitInMB;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    const allowedExtensions = ['jpg', 'jpeg', 'JPG', 'JPEG']; 
    const filePromises: Promise<{ file: File; width: number }>[] = [];
    console.log("Prueba 1", this.selectedFileIcon);
    let mensajeMB = '';
    let mensajeFormat = '';
  
    this.iconErrors.size = false;
    this.iconErrors.format = false;
    this.iconErrors.ErrorMB = [];
    this.iconErrors.ErrorFormat = [];
    this.iconErrors.ToShowMB = "";


    
    for (const file of this.selectedFileIcon) {
      if (file.size > maxSizeInBytes) {
        this.iconErrors.ErrorMB.push(file.name);
      } else {
        // Obtener la anchura del archivo
        const promise = new Promise<{ file: File; width: number }>((resolve) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
              resolve({ file, width: img.width });
            };
            img.src = event.target?.result as string;
          };
          reader.readAsDataURL(file);
        });
        filePromises.push(promise);
      }
    }
  
    Promise.all(filePromises).then((results) => {
      for (const result of results) {
        if (result.width < this.iconErrors.minW) {
          this.iconErrors.ErrorFormat.push(result.file.name);
        } 
      }
  
      if (this.iconErrors.ErrorMB.length > 0) {
        this.iconErrors.format = true;
        mensajeMB = `Los siguientes archivos superan el tamaño máximo: <br>${this.iconErrors.ErrorMB.join(', ')} `;
      }
  
      if (this.iconErrors.ErrorFormat.length > 0) {
        this.iconErrors.format = true;
        mensajeFormat = `Los siguientes archivos tienen un formato o dimensiones no permitidas: <br>${this.iconErrors.ErrorFormat.join(', ')}`;
      }
  
    });

    this.serviceTemplate.uploadImages(this.selectedFileIcon[0]).subscribe(
      (response) => {
       console.log(response);
       this.urlIcon = response.path;
       this.isCreatedIcon = true;
       console.log(this.urlIcon);
       this.jsonForm.get('icon')?.setValue({ path: this.urlIcon });
   } );

   this.changeState();

  }

  onMiniatureSelect(event: any) {
    this.selectedMiniatureFiles = event.addedFiles as File[];
    const maxMiniatureSizeInBytes = this.MiniatureErrors.maxSize * 1024 * 1024;
    const miniaturePromises: Promise<{ file: File; width: number }>[] = [];
    let miniatureMessageMB = '';
    let miniatureMessageFormat = '';

    this.MiniatureErrors.miniFiles = [];
    this.MiniatureErrors.FilesFormat = [];
    this.MiniatureErrors.errorMsgForMB = false;
    this.MiniatureErrors.errorMsgForFormat = false;

    for (const miniatureFile of this.selectedMiniatureFiles) {
      if (miniatureFile.size > maxMiniatureSizeInBytes) {
        this.MiniatureErrors.FilesErrorMB.push(miniatureFile.name);
      } else {
        // Obtener la anchura del archivo
        const promise = new Promise<{ file: File; width: number }>((resolve) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
              resolve({ file: miniatureFile, width: img.width });
            };
            img.src = event.target?.result as string;
          };
          reader.readAsDataURL(miniatureFile);
        });
        miniaturePromises.push(promise);
      }
    }

    Promise.all(miniaturePromises).then((results) => {
      for (const result of results) {
        if (result.width < this.iconErrors.minW) {
          this.MiniatureErrors.FilesFormat.push(result.file.name);
        } else {
          this.MiniatureErrors.miniFiles.push(result.file);
          console.log(this.MiniatureErrors.miniFiles);
        }
      }

      if (this.MiniatureErrors.FilesErrorMB.length > 0) {
        this.MiniatureErrors.errorMsgForMB = true;
        miniatureMessageMB = `Los siguientes archivos superan el tamaño máximo: <br>${this.MiniatureErrors.FilesErrorMB.join(', ')} `;
      }

      if (this.MiniatureErrors.FilesFormat.length > 0) {
        this.MiniatureErrors.errorMsgForFormat = true;
        miniatureMessageFormat = `Los siguientes archivos tienen un formato o dimensiones no permitidas: <br>${this.MiniatureErrors.FilesFormat.join(', ')}`;
      }
    });

    this.serviceTemplate.uploadImages(this.selectedMiniatureFiles[0]).subscribe(
      (response) => {
       console.log(response);
       this.urlMiniature = response.path;
       this.isCreatedThumbnail = true;
       console.log(this.urlMiniature);
       this.jsonForm.get('thumbnail')?.setValue({ path: this.urlMiniature });
   } );

   this.changeState();
  }

  clean() {
    this.iconErrors.size = false;
    this.iconErrors.ToShowMB = "";
    this.iconErrors.namesErrorToShowFormat = "";
    this.iconErrors.ErrorMB = [];
  }

  onRemoveMiniature(event: any) {
    this.selectedMiniatureFiles.splice(this.selectedMiniatureFiles.indexOf(event), 1);
    this.clean(); 
  }

  onRemove(event: any) {
    this.selectedFileIcon.splice(this.selectedFileIcon.indexOf(event), 1);
    this.clean(); 
  }

  sendInformation(){
    this.TemplateNameInfo = this.jsonForm.get('nameTemplate')?.value;

    const nuevoObjeto = {
      id: this.jsonForm.get('id')?.value,
      name: this.jsonForm.get('nameTemplate')?.value,
      properties: this.datos,
      thumbnail: this.isCreatedThumbnail ? this.urlMiniature: this.receivedData['thumbnail'],
      icon: this.isCreatedIcon ? this.urlIcon : this.receivedData['icon'],
      view_pulzo_revolution: this.jsonForm.get('view_pulzo_revolution')?.value
    };

    this.editTemplateService.editTemplate(nuevoObjeto).subscribe((response) =>{
      if(response.code == '201' || response.code == '200'){
        
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: "Plantilla Editada",
          didClose: () => {
            this.router.navigate(['/home-module-coordinador']);
          }
          
        });
      }
    });
  }
  
}
