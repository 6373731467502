import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-siderbar-coordinator',
  templateUrl: './siderbar-coordinator.component.html',
  styleUrls: ['./siderbar-coordinator.component.css']
})
export class SiderbarCoordinatorComponent {
  @Input() optionMenu: string | undefined;
  
  navigateToSlack(){
    window.open('https://pulzoworkspace.slack.com/archives/CUB8B5YJX')
  }
}
