import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { ButtonsIrisModule, HeaderModule, InputIrisModule, NavbarModule } from 'iris-front';
import { TranslocoRootModule } from './core/transloco-root.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { httpInterceptorProviders } from './core/interceptors';
import { LayoutModule } from './layout/layout.module';
import { ErrorsModule } from './modules/errors/errors.module';
import { DataSharingService } from './core/services';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from './modules/shared/shared.module';
import { HomeModule } from './modules/home/home.module';
import { NewsletterModule } from './modules/newsletter/newsletter.module';
import { GalleryModule } from './modules/gallery/gallery.module';
import { SeoModule } from './modules/seo/seo.module';
import { ModuleManagerModule } from './modules/module-management/module-manager.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { LinksListModule } from './modules/links-list/links-list.module';
import { MainMenuModule } from './modules/main-menu/main-menu.module';
import { VersionerModuleModule } from './modules/versioner-module/versioner-module.module';
import { FirstNoteScrollModule } from './modules/first-note-scroll/first-note-scroll.module';



@NgModule({
  providers: [
    httpInterceptorProviders, 
    DataSharingService,
    AppComponent,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    ButtonsIrisModule,
    InputIrisModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslocoRootModule,
    AppRoutingModule,
    LayoutModule,
    ErrorsModule,
    HeaderModule,
    NavbarModule,
    HomeModule,
    DragDropModule,
    NewsletterModule,
    GalleryModule,
    SharedModule,
    SeoModule,
    ModuleManagerModule,
    VersionerModuleModule,
    NgxDropzoneModule,
    NewsletterModule,
    FormsModule,
    LinksListModule,
    MainMenuModule,
    FirstNoteScrollModule
  ],
  
  bootstrap: [AppComponent],
})
export class AppModule { }
