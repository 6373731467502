<!--Imagen pantalla grande error 500-->
<div class="hidden md:block">
    <img class="" src="./assets/Error-500.jpg" />
    <div class="absolute left-28 top-80">
        <button (click)="redirectToLogin()" class="relative bg-white text-sm w-36 h-12 rounded-full">Volver al inicio</button>
    </div>
</div>
<!--Imagen pantalla pequeña-->
<div class="block md:hidden">
    <img class="" src="./assets/Error-500-mobile.jpg" />
    <div class="absolute bottom-20 left-24">
        <button (click)="redirectToLogin()" class="relative bg-white text-sm w-48 h-12 rounded-full">Volver al inicio</button>
    </div>
</div>