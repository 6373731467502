<app-header></app-header>

<div class="flex mt-2">
  <app-sidebar optionMenu="7"></app-sidebar>

  <div class="bg-white rounded-2xl shadow-md mx-auto mt-8 w-3/4 h-3/4 flex flex-col">
    
    <div class="p-10">
      <h1 class="text-2xl font-bold leading-8 mb-10">Versionador de artículos</h1>
     
      <form [formGroup]="formUrl">
        <label class="block text-base text-dark-light mb-3" for="url">URL del artículo 1</label>
        <input type="text" class="h-12 mt-1 pl-3 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-light-light" id="url" formControlName="url" placeholder="URL">
      
        <button (click)="sendUrl()" type="submit" class="w-44 h-10 font-bold py-2 px-4 mt-6 rounded-md hover:bg-green-700 flex items-center bg-green-700">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 5V19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span class="text-white font-semibold text-sm pl-3">Generar versión</span>
        </button>
      
      </form>
    </div>  
 
  </div> 

</div>  
