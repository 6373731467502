import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-siderbar-editor',
  templateUrl: './siderbar-editor.component.html',
  styleUrls: ['./siderbar-editor.component.css']
})
export class SiderbarEditorComponent {
  @Input() optionMenu: string | undefined;

  navigateToSlack(){
    window.open('https://pulzoworkspace.slack.com/archives/CUB8B5YJX')
  }
}
