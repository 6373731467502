import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FirstNoteScrollComponent } from './first-note-scroll/first-note-scroll.component';



@NgModule({
  declarations: [
    FirstNoteScrollComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class FirstNoteScrollModule { }
