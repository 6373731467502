<!-- <app-back-header link='/home-module-coordinador'></app-back-header> -->

<div class="w-[792px] m-auto">
    <button [routerLink]="['/home-module-editor']"  class="w-[79px] flex justify-between mb-6 mt-8 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M15 19L8 12L15 5" stroke="#038652" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="font-bold text-[13px] text-[#666]">{{ 'Modules.Back' | transloco }}</span>
    </button>
    <div class="w-[792px] flex justify-between mb-6">
        <h1 class="text-2xl font-bold">{{ 'ModuleManager.newModule' | transloco }}</h1>
        
        <button (click)="sendData()" class="w-[168px] h-10 rounded font-semibold text-white"
            [ngClass]="{'bg-[#CBCBCB]': !jsonForm.valid, 'button-active': jsonForm.valid}"
            [disabled]="!jsonForm.valid">
            {{ 'Modules.Next' | transloco }}
        </button>

    </div>
    <div class="w-[792px] h-min-[454px] mb-4 rounded-xl bg-[#fff] flex flex-col">
        <h2 class="mt-7 ml-6 mb-7 font-bold text-xl">{{ 'Modules.GeneralInformation' | transloco }}</h2>
        <form [formGroup]="jsonForm">
            <div class="flex flex-col items-start h-[79px] mb-10 ml-6 mr-6">
                <span class="font-semibold text-base text-[#8B8D97]">{{ 'Modules.ModuleName' | transloco }}</span>
                <input formControlName="name" class="w-full h-36px pb-2 pt-2 pl-4 pr-4 mt-2 mb-2 rounded-lg bg-[#EFF1F999]" placeholder="Ej. Módulo especial de fútbol" />
            </div>
        </form>
        <div class="flex flex-col ml-6 mr-6">
            <span class="mb-4 text-base font-semibold text-[#8B8D97]">{{ 'ModuleManager.typeModules' | transloco }}</span>
            <div class="grid grid-cols-4 mb-4 gap-6 items-start">
                <div *ngFor="let template of templates" [ngStyle]="{'border': template?.isActive ? '4px solid #81BCFF' : 'none', 'border-radius': template?.isActive ? '8px' : '0'}"  class="w-[168px] h-[184px] mr-6">
                    <div (click)="isActiveTypeModule(template.id)"  
                         class="flex items-center justify-center bg-[#F4F5FA] rounded-t-lg w-full h-[144px]">
                      <img class="w-[112px] h-[112px] object-contain" src="{{template?.thumbnail}}"/>
                    </div>
                    <div  class="min-h-10 w-full bg-[#E7E7E7] mt-[-7px] flex flex-row rounded-b-lg  items-center">
                      <div class="w-auto h-6 flex ml-2 items-center">
                        <div  class="w-6 h-6 rounded-sm flex items-center justify-center mr-2 bg-[#F4F5FA]">
                          <img src="{{template?.icon}}" class="w-6 h-6"/>
                        </div>
                        <span class="font-semibold text-sm text-[#676767]">{{template?.name}}</span>
                      </div>
                    </div>
                </div>                                             
            </div>
        </div>
    </div>
</div>