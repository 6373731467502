<app-back-header link='/home-versioner'></app-back-header>

<div class="min-h-screen flex flex-col items-center justify-center">
  <div class="w-full max-w-3xl mt-10 bg-white p-8 rounded-lg shadow">
    <h1 class="text-2xl not-italic font-bold leading-8 mb-6">Versionador de artículos</h1>

    <div *ngIf="versionData?.data" class="space-y-6">
      <div class="flex flex-col">
        <label class="text-base text-dark-light">Título</label>
        <div class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm bg-light-light p-4">{{ versionData?.data?.titulo }}</div>
      </div>
      
      <div class="flex flex-col">
        <label class="text-base text-dark-light">Subtítulo</label>
        <div class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm bg-light-light p-4">{{ versionData?.data?.subtitulo }}</div>
      </div>
      
      <div class="flex flex-col">
        <label class="text-base text-dark-light">Cuerpo del texto</label>
        <div class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm bg-light-light p-4">{{ versionData?.data?.body }}</div>
      </div>
      
      <div class="flex flex-col">
        <label class="text-base text-dark-light">URL Utilizada</label>
        <a [href]="versionData?.data?.url_usada" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm bg-light-light p-4">{{ versionData?.data?.url_usada }}</a>
      </div>
    </div>
    
  </div>
</div>
