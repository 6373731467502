<app-header title="{{'home.title' | transloco}}"></app-header>

<div class=" flex mt-2">

    <app-sidebar optionMenu="1"></app-sidebar>

    <div class="flex flex-col mt-5 h-full w-full justify-center items-center">
        <div class="flex flex-col items-start">
            <img class="py-6" src="assets/welcome.png" alt="" srcset="">
        </div>
    </div>
</div>