import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeModulesEditorComponent } from './home-modules-editor/home-modules-editor.component';
import { CreateModuleComponent } from './create-module/create-module.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NewTemplateComponent } from './new-template/new-template.component';
import { NewModuleComponent } from './new-module/new-module.component';
import { HomeModulesCoordinadorComponent } from './home-modules-coordinador/home-modules-coordinador.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { EditModuleComponent } from './edit-module/edit-module.component';


@NgModule({
  declarations: [
    HomeModulesEditorComponent,
    CreateModuleComponent,
    NewTemplateComponent,
    EditTemplateComponent,
    HomeModulesCoordinadorComponent,
    NewModuleComponent,
    EditModuleComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule
  ]
})
export class ModuleManagerModule { }
