import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderModule, NavbarModule } from 'iris-front';

import { InternalServerComponent } from './internal-server/internal-server.component';
import { NotFoundComponent } from './not-found/not-found.component';



@NgModule({
  declarations: [NotFoundComponent, InternalServerComponent],
  imports: [RouterModule, HeaderModule, NavbarModule],
})
export class ErrorsModule {}
