<app-header></app-header>

<div class="flex mt-2">
    <app-sidebar optionMenu="5"></app-sidebar>
    
    <div class="w-[890px] m-auto mt-12">
        <div class="w-[890px] flex justify-between mb-6">
            <h1 class="text-2xl font-bold">{{ 'newModule.Templates' | transloco }}</h1>
            
            <button class="items-center w-44 h-10 border rounded bg-green-MainColor flex hover:bg-green-dark" [routerLink]="['/new-template']">
                <i class="fas fa-plus text-white px-3"></i>
                <span class="text-white text-center font-semibold text-sm m-auto"> {{ 'Modules.NewTemplate' | transloco }}</span>
            </button>
        </div>
        <div class="w-[890px] h-[454px] rounded-xl flex flex-col">
            <div class="flex flex-wrap -mx-3">
                <div class="w-1/4 px-3 mb-6" *ngFor="let template of templates">
                    <div class="w-[210px] h-[184px]">
                        
                        <div class="flex items-center justify-center rounded-t-lg bg-white w-full h-[144px]">
                            <div class="bg-[#FDFDFD] rounded-lg h-36 flex justify-center items-end w-full">
                                <div class="p-4 flex justify-center items-center w-full h-full relative">
                                    <img [src]="template.thumbnail" alt="Thumbnail" class="w-full h-full object-cover">
                                    <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center opacity-0 hover:opacity-100 transition duration-300 backdrop-filter hover:backdrop-blur-md">
                                        <button (click)="editTemplate(template.id)" class="bg-blue-500 text-white py-1 px-4 mr-2  rounded-full"><i class="fas fa-th-list mr-2"></i>{{ 'globals.edit' | transloco }}</button>
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="h-10 w-full bg-[#E7E7E7] flex flex-row rounded-b-lg  items-center">
                            <div class="w-auto h-6 flex ml-2 items-center">
                                <div class="w-6 h-6 rounded-sm flex items-center justify-center mr-2 bg-[#F4F5FA]">
                                    <img src="{{ template.icon }}" alt="icon" class="w-full h-full object-cover">
                                </div>
                                <span class="font-semibold text-sm text-[#676767]">{{ template.name }}</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>    
    </div>
    
    <div *ngIf="modalOpen">
        <app-delete-modal></app-delete-modal>
    </div>
    
</div>
