import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SidebarComponent} from './sidebar/sidebar.component';
import { SiderbarCoordinatorComponent} from './siderbar-coordinator/siderbar-coordinator.component';
import { SiderbarEditorComponent } from './siderbar-editor/siderbar-editor.component';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { BackHeaderComponent } from './back-header/back-header.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { ModalComponent } from './modal/modal.component';




@NgModule({
  declarations: [
    SidebarComponent,
    SiderbarEditorComponent,
    SiderbarCoordinatorComponent,
    HeaderComponent,
    BackHeaderComponent,
    DeleteModalComponent,
    ModalComponent
  ],
  imports: [
    CommonModule, TranslocoModule, RouterModule
  ],
  exports: [
    TranslocoModule, 
    RouterModule, 
    SidebarComponent,
    SiderbarEditorComponent, 
    SiderbarCoordinatorComponent,
    HeaderComponent, 
    BackHeaderComponent, 
    DeleteModalComponent
  ]
})
export class SharedModule { }
