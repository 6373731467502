import { Component, OnInit } from '@angular/core';
import { ApiResponse } from 'src/app/models/verioner-url';

@Component({
  selector: 'app-article-versioner',
  templateUrl: './article-versioner.component.html',
  styleUrls: ['./article-versioner.component.css']
})
export class ArticleVersionerComponent implements OnInit {
  versionData: ApiResponse | null = null;

  ngOnInit(): void {
    const storedData = sessionStorage.getItem('versionData');
    
    if (storedData) {
      try {
        this.versionData = JSON.parse(storedData);
 
        if (this.versionData.data) {
          //console.log('Datos del artículo:', this.versionData.data);
        } else {
          console.error('No se encontraron datos completos en la respuesta.');
        }
        
      } catch (e) {
        console.error('Error al parsear los datos de sessionStorage:', e);
      }
    } else {
      console.error('No se encontraron datos en sessionStorage');
    }

  }
  
}
